<template>
  <div class="mock-toggle">
    <ElSwitch v-model="isMocking" active-text="mock" inactive-text="mock" inline-prompt :loading="registeringMock" @change="toggleMock" />
  </div>
</template>

<script setup lang="ts">
import { mockApi } from '@/stores/storage';

const isMocking = ref(mockApi.value);
const registeringMock = ref(false);
const msw = window['_msw'];

const toggleMock = async (value: string | number | boolean) => {
  registeringMock.value = true;

  if (value) await msw?.start();
  else msw?.stop();

  mockApi.value = Boolean(value);
  registeringMock.value = false;
};
</script>

<style scoped lang="scss">
.mock-toggle {
  .el-switch {
    margin: 0;
    padding: 0;
  }
}
</style>
