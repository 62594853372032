<template>
  <svg class="icon-guide" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 5H2V19H12V5Z" stroke="currentColor" />
    <path d="M22 21H2" stroke="currentColor" stroke-linecap="square" />
    <path d="M19.4 5H22V19H12" stroke="currentColor" />
    <path d="M19.4 1V15L12 19V5L19.4 1Z" stroke="currentColor" stroke-linejoin="bevel" />
    <path d="M7 15.0799V10.9199H5.88" stroke="currentColor" />
    <path d="M8.12 15.0798H5.88" stroke="currentColor" />
    <path d="M7 9.31995V8.94995" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
