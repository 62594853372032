declare module 'vue' {
  interface ComponentCustomProperties {
    $to: <T>(msg: T, prefix?: string, props?: unknown[]) => string | T;
    $tf: (msg: unknown, fallback: string | (() => string)) => string;
    $tt: (msg: string | number) => string | undefined;
  }
}

export const translateOptional = (i18n, msg: unknown, prefix = '', props?: unknown[]) => {
  if (typeof msg === 'string' && msg !== '' && String(prefix + msg).indexOf('.') !== -1 && i18n.te(prefix + msg)) return i18n.t(prefix + msg, props);
  return msg;
};

export const translateWithFallback = (i18n, msg: unknown, fallback: string | (() => string) = '') => {
  if (typeof msg === 'string' && i18n.te(msg)) return i18n.t(msg);
  if (typeof fallback === 'function') return fallback();
  return fallback;
};

export const i18nUtils = {
  install(app: any, { i18n }: { i18n: any }): void {
    app.config.globalProperties.$to = (msg: unknown, prefix = '', props?: unknown[]) => {
      return translateOptional(i18n, msg, prefix, props);
    };

    app.config.globalProperties.$tf = (msg: unknown, fallback: string | (() => string) = '') => {
      return translateWithFallback(i18n, msg, fallback);
    };

    // translate tooltip
    app.config.globalProperties.$tt = (msg: string | number) => {
      const key = `${msg}_tooltip`;
      if (i18n.te(key)) return i18n.t(key);
    };
  },
};
