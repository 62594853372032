import { z } from 'zod';

export const CloudIdpConfig = z.object({
  url: z.string(),
  authority: z.string(),
  clientId: z.string(),
  apiUrl: z.string(),
});

export const Config = z.object({
  roles: z.string().array(), // NOTE: We could use type ZodRoleEnum here, but for compatibility reasons we accept all strings and verify this later
  EXACT: CloudIdpConfig,
  TESTPORTAL: CloudIdpConfig,
  externalLinks: z.record(z.string(), z.string()),
  userGuideLinks: z.object({
    base: z.string(),
    bugs: z.string(),
    projectReports: z.string(),
    support: z.string(),
    tagGroups: z.string(),
    testPlans: z.string(),
    testRepository: z.string(),
    testResults: z.string(),
  }),
});
export type Config = z.infer<typeof Config>;

export const TokenResponse = z.object({
  access_token: z.string(),
  refresh_token: z.string(),
  expires_in: z.number(),
});
export type TokenResponse = z.infer<typeof TokenResponse>;

export const RefreshTokenResponse = z.object({
  access_token: z.string(),
  expires_in: z.number(),
});
export type RefreshTokenResponse = z.infer<typeof RefreshTokenResponse>;

export const AccessTokenPayload = z.object({
  username: z.string().optional(),
  preferred_username: z.string().optional(),
  prj: z.string().optional(),
});
export type AccessTokenPayload = z.infer<typeof AccessTokenPayload>;
