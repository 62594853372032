import {
  createRouter,
  createWebHistory,
  type RouteLocationNormalized,
  type RouteLocationNormalizedLoaded,
  type RouteLocationRaw,
  type Router,
  type RouteRecordName,
} from 'vue-router';
import { DASHBOARD, ERROR, LOGIN, LOGIN_MOCK, LOGOUT } from '@route-types';
import routes from '@routes';
import { VueProgressbar } from '@jambonn/vue-next-progressbar';
import { serializeError } from '@/errorHandler';
import { Region } from '@/models/region/Region';
import { useAuthStore } from '@/stores/auth';
import { useSystemStore } from '@/stores/system';
import { ROUTE_UNKNOWN } from '@/utils/error';
import { hasAllRoles, metas } from '@/utils/navigation';

interface ScrollPositionElement extends ScrollToOptions {
  el: string | Element;
}

type ScrollPositionCoordinates = {
  behavior?: ScrollOptions['behavior'];
  left?: number;
  top?: number;
};

const router: Router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  async scrollBehavior(
    to: RouteLocationNormalized,
    from: RouteLocationNormalizedLoaded,
    savedPosition: ScrollPositionCoordinates | null
  ): Promise<ScrollPositionCoordinates | ScrollPositionElement | false | void> {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return { el: to.hash };
    }

    if (!to.meta?.isScrollUpDisabled && from.name !== to.name) {
      return { left: 0, top: 0, behavior: 'smooth' };
    }
  },
});

export const loginPageWithRedirect = (redirectFrom: string = router.currentRoute.value.fullPath): RouteLocationRaw => {
  return { name: LOGIN, force: true, query: { redirectFrom } };
};

router.beforeEach(async (to, from) => {
  if (to.name !== from.name) VueProgressbar.start();
  if (!to.matched.length) return { name: ERROR, query: { error: ROUTE_UNKNOWN } };

  const systemStore = useSystemStore();

  try {
    await systemStore.initPromise;
  } catch (e) {
    if (to.matched[0].name !== ERROR) {
      return { name: ERROR, query: { message: 'Authorization failed', error: serializeError(e) } };
    }
  }

  const routeAllowlist: RouteRecordName[] = [ERROR, LOGOUT]; // no login needed
  if (window.location.toString().startsWith('http://localhost')) routeAllowlist.push(LOGIN_MOCK);
  if (to.matched[0].name && routeAllowlist.includes(to.matched[0].name)) {
    return true;
  }

  const { redirectFrom } = to.query;
  const { isLoggedIn } = useAuthStore();
  if (to.matched[0].name === LOGIN) {
    // Don't allow LOGIN dialogue if already logged in:
    if (isLoggedIn) {
      if (typeof redirectFrom === 'string' && redirectFrom && redirectFrom !== '/logout') {
        return redirectFrom;
      } else {
        return { name: DASHBOARD };
      }
    }
    return true;
  }

  // Redirect to LOGIN, if user is not logged in
  if (!isLoggedIn && redirectFrom !== '/logout') return loginPageWithRedirect(to.fullPath);

  if (!to.name) {
    // eslint-disable-next-line no-console
    console.warn('Invalid route', to); // only named routes are allowed!
    return false;
  }

  // Redirect to DASHBOARD, if user doesn't have all access-rights
  if (!hasAllRoles(metas[to.name].roles)) {
    return {
      name: DASHBOARD,
    };
  }

  if (!to.params.projectId && metas[to.name].hasProjectScope) {
    return { ...to, params: { ...to.params, projectId: systemStore.selectedProjectId } };
  }

  if (!to.params.region && metas[to.name].hasRegionScope) {
    return { ...to, params: { ...to.params, region: Region.enum.EU } };
  }

  return true;
});

export const routeHistory: RouteLocationNormalized[] = [];
router.afterEach((to, from, failure) => {
  if (!failure) routeHistory.push(to);
  if (to.name !== from.name) VueProgressbar.done();
});

export default router;
