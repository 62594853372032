import dayjs from 'dayjs';
import { RefreshTokenResponse } from '@/models/Auth';
import { postOAuth2Token } from '@/services/AuthShared';
import { hasOwnProperty } from '@/utils/assertions';

/**
 * NOTE: To inspect and debug in Chrome just open "chrome://inspect/#workers"
 */

interface SharedWorkerGlobalScope {
  onconnect: (event: MessageEvent) => void;
}

/** Receive tokens and expiryDate and register timeout (After login) */
export const RESET = 'RESET' as const;

/** Resume previous state (Register timeout if not there) */
export const RESUME = 'RESUME' as const;

/** Delete state and broadcast LOGOUT event */
export const LOGOUT = 'LOGOUT' as const;

/** New accessToken is being received. Wait for it to be resolved. */
export const PENDING = 'PENDING' as const;

/** New accessToken and expiryDate received */
export const REFRESH = 'REFRESH' as const;

export type WorkerCall =
  | {
      type: typeof LOGOUT | typeof PENDING;
    }
  | {
      type: typeof RESET | typeof RESUME;
      accessToken: string;
      refreshToken: string;
      expiryDate: string;
      cognitoUrl: string;
      clientId: string;
    }
  | {
      type: typeof REFRESH;
      accessToken: string;
      refreshToken: string;
      expiryDate: string;
    };

export const isWorkerCall = (obj: unknown): obj is WorkerCall => hasOwnProperty(obj, 'type');

export const toExpiryDate = (expiresIn: number) =>
  dayjs()
    .add(expiresIn - 10, 'second')
    .format();

const connections: MessagePort[] = [];
let timeoutId: ReturnType<typeof setTimeout> | null = null;
let accessToken: string | null = null;
let refreshToken: string | null = null;
let expiryDate: string | null = null;
let cognitoUrl: string | null = null;
let clientId: string | null = null;

const _self: SharedWorkerGlobalScope = self as any;

_self.onconnect = connectEvent => {
  const port = connectEvent.ports[0];
  connections.push(port);

  port.onmessage = e => {
    if (!isWorkerCall(e.data)) return;

    switch (e.data.type) {
      case RESET:
        if (timeoutId !== null) {
          clearTimeout(timeoutId);
          timeoutId = null;
        }

      case RESUME:
        if (timeoutId !== null && expiryDate !== e.data.expiryDate) {
          clearTimeout(timeoutId);
          timeoutId = null;
        }

        accessToken = e.data.accessToken;
        refreshToken = e.data.refreshToken;
        expiryDate = e.data.expiryDate;
        cognitoUrl = e.data.cognitoUrl;
        clientId = e.data.clientId;

        if (timeoutId === null) {
          registerTimeout();
        }
        break;

      case LOGOUT:
        if (timeoutId === null) return;

        accessToken = null;
        refreshToken = null;
        expiryDate = null;

        clearTimeout(timeoutId);
        timeoutId = null;

        broadcast({ type: LOGOUT });
        break;
    }
  };
};

const broadcast = (event: WorkerCall) => connections.forEach(p => p.postMessage(event));

const registerTimeout = () => {
  const now = dayjs();
  const expiry = dayjs(expiryDate);
  const timeoutMs = now.isAfter(expiry) ? 0 : expiry.diff(now);

  timeoutId = setTimeout(
    async () => {
      if (!refreshToken || !cognitoUrl || !clientId) return;

      broadcast({ type: PENDING });

      try {
        /** NOTE: The following callback is duplicated inside Auth.ts:postRefresh(). Apply changes to both! */
        const refreshResponse = await postOAuth2Token(RefreshTokenResponse, cognitoUrl, {
          client_id: clientId,
          grant_type: 'refresh_token',
          refresh_token: refreshToken,
        });

        accessToken = refreshResponse.access_token;
        expiryDate = toExpiryDate(refreshResponse.expires_in);

        broadcast({ type: REFRESH, accessToken, refreshToken, expiryDate });
        registerTimeout();
      } catch {
        accessToken = null;
        broadcast({ type: LOGOUT });
      }
    },
    Math.min(timeoutMs, 2_147_483_647) // (about 24.8 days) to avoid integer overflow
  );
};
