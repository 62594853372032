import axios, { type AxiosError, type AxiosResponse } from 'axios';
import { isArray } from 'lodash-es';
import { z } from 'zod';
import { hasOwnProperty } from '@/utils/assertions';
import type { ResponseError } from '@/models/error/ResponseError';

export const ROUTE_UNKNOWN = 'ROUTE_UNKNOWN';

export const getErrorResponse = (e): AxiosResponse | undefined => {
  if (axios.isAxiosError(e)) return e?.response;
};

export const is4xxError = (e: AxiosResponse) => e.status >= 400 && e.status < 500;

export const firstResponseErrorFromError = (e: AxiosError): ResponseError | undefined => {
  const errors = e.response && hasOwnProperty(e.response.data, 'errors') ? e.response.data.errors : undefined;
  return isArray(errors) && errors.length > 0 ? errors[0] : undefined;
};

export const zodCatchParsing = <T>(zodType: z.ZodType<T>) => ({
  fallback: (value: T) => zodType.catch(value) as typeof zodType,
});
