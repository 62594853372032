<template>
  <PopoverSelector
    v-model="isOpen"
    class="project-selector"
    :class="{ expanded: isSidebarExpanded }"
    :popover-title="$t('navigation.SELECT_PROJECT')"
    :popover-content-width
    :tooltip-content="systemStore.selectedProjectId!"
    :show-tooltip="!isSidebarExpanded"
  >
    <template #button>
      <div class="project-selector-button" :class="{ expanded: isSidebarExpanded }">
        <Avatar :name="systemStore.selectedProjectId!" />
        <span class="project-name bold-md ellipsis">{{ systemStore.selectedProjectId }}</span>
      </div>
    </template>

    <div class="selector-body">
      <span class="info">{{ $t('navigation.reloadProject') }}</span>

      <div class="selection">
        <ElTooltip
          v-for="projectId in authStore.availableProjectIds"
          :key="projectId"
          :content="$t('navigation.onlyProject')"
          :disabled="authStore.availableProjectIds.length !== 1"
        >
          <div
            class="selectable-item focus-shadow-inset"
            :class="{ isActive: systemStore.selectedProjectId === projectId }"
            role="button"
            tabindex="0"
            @click="handleSave(projectId)"
            @keypress.enter="handleSave(projectId)"
          >
            <span class="regular-md ellipsis">{{ projectId }}</span>
          </div>
        </ElTooltip>
      </div>
    </div>
  </PopoverSelector>
</template>

<script setup lang="ts">
import Avatar from '@/components/Avatar.vue';
import PopoverSelector from '@/components/PopoverSelector.vue';
import { isSidebarExpanded } from '@/composables/useLayout';
import router from '@/router';
import { useAuthStore } from '@/stores/auth';
import { useSystemStore } from '@/stores/system';
import { metas } from '@/utils/navigation';

const authStore = useAuthStore();
const systemStore = useSystemStore();

const isOpen = ref(false);

// sync with css
const widthItem = 254; // --selectable-item-width
const gap = 10; // --selectable-items-gap

const popoverContentWidth = computed(() => {
  const len = authStore.availableProjectIds.length;
  const cols = len <= 4 ? 1 : 2;
  return widthItem * cols + gap * (cols - 1);
});

const handleCancel = () => {
  isOpen.value = false;
};

const handleSave = (projectId: string) => {
  handleCancel();

  const isProjectAlreadySelected = projectId === systemStore.selectedProjectId;
  if (isProjectAlreadySelected) return;

  systemStore.cleanUserSession();

  const name = router.currentRoute.value.name ?? '';
  const { hasProjectDetails, hasProjectScope } = metas[name] ?? {};

  if (hasProjectDetails) {
    // route to parent, with new project
    router.push({
      name: router.currentRoute.value.meta.parent,
      params: { projectId },
    });
  } else if (hasProjectScope) {
    // route to self, with new project
    router.push({
      name,
      params: { ...router.currentRoute.value.params, projectId },
    });
  } else {
    // route to self, with new project, no page reload
    systemStore.setProjectId(projectId);
  }
};
</script>

<style scoped lang="scss">
@use '@/assets/theme/variables';

.project-selector {
  --popover-selector-arrow-padding: 4px;

  &.expanded {
    --popover-selector-arrow-padding: 24px;
  }

  .project-selector-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .project-name {
      display: none;
    }

    &.expanded {
      justify-content: flex-start;

      padding: 10px 20px;

      .project-name {
        display: initial;
      }
    }
  }
}

.selector-body {
  --selector-text-color: light-dark(var(--color-ebony), var(--color-background-light-original));

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;

  .selection {
    align-self: stretch;

    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    gap: var(--selectable-items-gap, 10px);

    width: 100%;

    .selectable-item {
      display: flex;
      align-items: center;
      gap: 10px;

      width: var(--selectable-item-width, 254px);
      height: 50px;
      padding: 0 15px;
      border-radius: 10px;
      border: 1px solid var(--color-background-light);

      &:hover,
      &.isActive {
        cursor: pointer;
        background-color: var(--color-background-light);
        color: var(--selector-text-color);
      }
    }
  }
}
</style>
